import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import Particles from "../components/Particles/index"
import PersonalProjectsPage from "../components/PersonalProjectsPage/index"

const OtherProjects = () => (
  <Layout>
    <SEO title="Manfacture" />
    <Link to="/">Home</Link>
    {/* <Particles /> */}
    <PersonalProjectsPage />
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <ul>
      Some personal projects that have reallly inspired me - please note further
      details on all projects coming soon pending site completion
    </ul>
    <li>Social Impact - NDG Social Enterprises & Impact Generated</li>
    <li>Sustainability - Biofuels, Solar Energy, Biodomes and more</li>
    <li>Sport & Spriritual</li>
    {/* <li>Game making - the process</li>
    <li>Personal Business Websites</li> */}
  </Layout>
)

export default OtherProjects
