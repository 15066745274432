import React from "react"
import css from "../PersonalProjects/PersonalProjects.module.css"
import Particle from "../Particles/index"
import latorre from "../../images/latorre.jpg"

function PersonalProjectsPage() {
  return (
    <div className={css.hexagon}>
      <Particle />
      <div className={css.wrapper}>
        <div className={css.item1}>
          <h2>CNC Maching</h2>
        </div>
      </div>
      <section>
        <article>
          <figure>
            <h2>Bio Domes</h2>
          </figure>
          <img alt src={latorre} />
        </article>
        <article>
          <figure>
            <h2>Carpentry</h2>
          </figure>
          <img alt src={latorre} />
        </article>
        <article>
          <figure>
            <h2>Trekking</h2>
          </figure>
          <img alt src={latorre} />
        </article>
        <article>
          <figure>
            <h2>Social Impact</h2>
          </figure>
          <img alt src={latorre} />
        </article>
        <article>
          <figure>
            <h2>Music</h2>
          </figure>
          <img alt src={latorre} />
        </article>
        <article>
          <figure>
            <h2>Swimming</h2>
          </figure>
          <img alt src={latorre} />
        </article>
        <article>
          <figure>
            <h2>BMX</h2>
          </figure>
          <img alt src={latorre} />
        </article>
        <article>
          <figure>
            <h2>Running</h2>
          </figure>
          <img alt src={latorre} />
        </article>
        <article>
          <figure>
            <h2>Bio Fuel</h2>
          </figure>
          <img alt src={latorre} />
        </article>
        <article>
          <figure>
            <h2>Solar Energy</h2>
          </figure>
          <img alt src={latorre} />
        </article>
        <article>
          <figure>
            <h2>Yoga/Me-ditation</h2>
          </figure>
          <img alt src={latorre} />
        </article>
        <article>
          <figure>
            <h2>Aqua -ponics</h2>
          </figure>
          <img alt src={latorre} />
        </article>
      </section>

      <svg width="0" height="0">
        <defs>
          <clipPath id="hexagono" clipPathUnits="objectBoundingBox">
            <polygon points=".25 0, .75 0, 1 .5, .75 1, .25 1, 0 .5" />
          </clipPath>
        </defs>
      </svg>
      <h2>Get in Touch</h2>
    </div>
  )
}

export default PersonalProjectsPage
